





















import ConditionWrapperMixin from "@/components/TriggerSetup/conditions/ConditionWrapperMixin";
import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'

import { Component, Mixins } from 'vue-property-decorator'

@Component({})
export default class ReactionsConditionsWrapper extends Mixins(ConditionSetupView, ConditionWrapperMixin) {
}
